import React from 'react';
import {
  Link,
  List,
  PageHero,
  PageWithSubNav,
  Paragraph,
  Section,
} from '../../../../components';

import pageHeroData from '../../../../data/pages/guidelines.yml';
import auditResults from '../../../../data/accessibility/auditResults';
import AccessibilityAuditResultsTable from '../../../../components/accessibility/audit/AccessibilityAuditResultsTable';

const SharedPage = ({ pageType }) => {
  return (
    <PageWithSubNav
      pageType={pageType}
      title="Accessibility Audit"
      designCode
      subnav="guidelines">
      <PageHero
        heroData={pageHeroData}
        tierTwo="Accessibility"
        tierThree="Audit"
      />

      <Section title="Audit Details">
        <Paragraph>
          At the end of each quarter, or as we fix warning and violations, we
          will update these tables. Our testing gives us indications for three
          categories, further explained in our{' '}
          <Link href="https://sync.hudlnet.com/display/UNIFORM/Uniform+Accessibility+Audits">
            internal documentation
          </Link>
          :
        </Paragraph>
        <List className="uni-margin--one--bottom">
          <li>
            <strong>Visual:</strong> color contrast, state change, reduced
            motion, and increased font size.
          </li>
          <li>
            <strong>Interactive:</strong> keyboard use, touch target size
            (mobile), and aria properties/semantic markup.
          </li>
          <li>
            <strong>Automated:</strong> jest-axe and Stylelint.
          </li>
        </List>
        <Paragraph>
          The details column includes a brief explanation of every warning or
          violation for that component. If a particular component has both a
          warning and violation for a particular test group, it will show
          violation, but both will be explained in the details column.
        </Paragraph>
        <Paragraph>
          Every warning and violation is tracked on our{' '}
          <Link href="https://hudl-jira.atlassian.net/jira/software/c/projects/FEP/boards/382/backlog?epics=visible&issueLimit=100&selectedEpic=FEP-3286">
            Jira board
          </Link>{' '}
          — and we'll address one or two each sprint. Despite the current
          warnings and violations, we've made a lot of progress and feel
          confident the remaining issues are minor.
        </Paragraph>
      </Section>
      <Section title="Web Component Accessibility Status">
        <Paragraph>
          This table includes the results of the accessibility audit of our
          React (web) components. Keep scrolling to find the results of our
          React Native (mobile) component audit.
        </Paragraph>
        <small>
          Last updated{' '}
          {auditResults.React.lastUpdated.toLocaleDateString('default', {
            dateStyle: 'long',
          })}
          .
        </small>
        <Paragraph>
          <AccessibilityAuditResultsTable platform="React" />
        </Paragraph>
      </Section>
      <Section title="Mobile Component Accessibility Status">
        <Paragraph>
          This table includes the results of the accessibility audit of our
          React Native (mobile) components. We do not currently automate any
          accessibility testing on our mobile components, but plan to add that
          in the future.
        </Paragraph>
        <small>
          Last updated{' '}
          {auditResults['React Native'].lastUpdated.toLocaleDateString(
            'default',
            {
              dateStyle: 'long',
            }
          )}
          .
        </small>
        <Paragraph>
          <AccessibilityAuditResultsTable platform="React Native" />
        </Paragraph>
      </Section>
    </PageWithSubNav>
  );
};

export default SharedPage;
